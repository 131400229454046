import { number, percentage, currency, convertFromCapsUnderscore } from 'core-ui/plugins/CoreUiFilters.js'
import { labelizeBidStrategy } from '@/assets/js/pluginUtils/BackpackFilters.js'
import moment from 'moment'

const formatter = (format = '', ...filterArgs) => (value, key, item) => {
  if (typeof value === 'number') {
    switch (format) {
      case 'number':
        return number(value, false, true)
      case 'fixed':
        return number(value, ...filterArgs)
      case 'percentage':
        return percentage(value, ...filterArgs)
      case 'currency':
        return value ? currency(value, 0, { code: item?.currencyCode || item?._currencyCode }) : '-'
      case 'currency-precise':
        return currency(value, 2, { code: item?.currencyCode || item?._currencyCode })
      default:
        return value
    }
  } else {
    switch (format) {
      case 'from-caps-underscore':
        return convertFromCapsUnderscore(value)
      case 'date':
        if (value) {
          return moment(value).format('M/D/YYYY')
        } else {
          return value
        }
      case 'bid-strategy':
        return labelizeBidStrategy(value)
      default:
        return value
    }
  }
}

const guardrailClassMap = {
  'low-concern': 'guardrail-concern',
  'high-concern': 'guardrail-concern',
  'low-warning': 'guardrail-warning',
  'high-warning': 'guardrail-warning',
  'low-alarm': 'guardrail-alarm',
  'high-alarm': 'guardrail-alarm'
}

const tdClassFormatter = (value, key, item, existingClasses = '') => {
  existingClasses += ' font-numeric' // function is only called on numbers
  existingClasses = (key === 'spendYesterday') ? `${existingClasses} font-italic` : existingClasses

  if (item && item[`${key}Status`]) { // low, high * (concerns, warnings, alerts)
    return `${existingClasses} ${guardrailClassMap[item[`${key}Status`]]}`
  }

  return existingClasses
}

const networkFilter = (items, filter, key) => {
  let filteredPlans = items
  filteredPlans = filteredPlans.filter(p => {
    if (p.partnerObjects) {
      for (const partnerObject of p.partnerObjects) {
        if ((filter.checkboxValues.includes(partnerObject.partnerId) ||
          (filter.checkboxValues.length === 1 && filter.checkboxValues[0] === 'errors')) &&
          (!filter.checkboxValues.includes('errors') ||
            (filter.checkboxValues.includes('errors') && partnerObject.lastError))) {
          return true
        }
      }
    }
    return false
  })
  return filteredPlans
}

const creativePlanFilter = (items, filter, key) => {
  let filteredPlans = items
  filteredPlans = filteredPlans.filter(p => {
    if (p.name?.toLowerCase().includes(filter.text.toLowerCase())) {
      return true
    }
    if (p.adSpec && p.adSpec.textAssets) {
      for (const asset of p.adSpec.textAssets) {
        if (asset.text.toLowerCase().includes(filter.text.toLowerCase())) {
          return true
        }
      }
    }
    return false
  })
  return filteredPlans
}

const sharedColumnDefinitions = {
  // ACTIONS - these are keyless and agnostic to the item, thus their keys can change,
  // as long as the template's name above also changes
  accountHold: { key: 'accountHold', label: 'Hold', thClass: 'text-center', tdClass: 'text-center', sortable: false, defaultWidth: 45 },
  actions: { key: 'actions', label: '', sortable: false },
  accountName: { key: 'accountName', label: 'Account', class: 'text-left truncate', defaultWidth: 250 },
  adGroupName: { key: 'adGroupName', label: 'Ad Group', class: 'text-left truncate', sortable: false, defaultWith: 250 },
  adGroup: { key: 'adGroup', label: 'Ad Group', class: 'text-left truncate', sortable: false, defaultWith: 250 },
  campaign: { key: 'campaign', label: 'Campaign', class: 'text-left truncate', sortable: false, defaultWith: 250 },
  adGroupPlanName: { key: 'adGroupPlanName', label: 'Ad Group', class: 'text-left truncate', sortable: true, defaultWidth: 250 },
  attachedFeeds: { key: 'attachedFeeds', label: 'Attached Feeds', class: 'text-left', sortable: true },
  searchTerm: {
    key: 'searchTerm',
    label: 'Search Term',
    defaultWidth: 250,
    class: 'text-left truncate',
    sortable: true
  },
  // device: {
  //   key: 'device',
  //   label: 'Device',
  //   defaultWidth: 250,
  //   class: 'text-left truncate'
  // },
  profileAdjustedPacing: {
    key: 'profileAdjustedPacing',
    label: 'Adj. Pacing',
    formatter: formatter('percentage'),
    tdClass: 'font-numeric',
    tooltip: 'Projected end of month pacing matched to your pacing strategy curve, includes current day updates, updated hourly',
    summable: {
      sum: false,
      average: false,
      format: 'percentage'
    }
  },
  allowReallocation: { key: 'allowReallocation', defaultWidth: 150 },
  approvedAccountBudget: {
    key: 'approvedAccountBudget',
    label: 'This Mo. Budget',
    thClass: 'text-right',
    formatter: formatter('currency-precise'),
    tdClass: 'font-numeric',
    sortable: true,
    tooltip: 'Total approved amount of monthly recurring budgets as of right now. This value is independent of the viewable time range.  Items in parenthesis are temporary budget adjustments.',
    summable: {
      sum: true,
      average: true,
      format: 'currency-precise'
    }
  },
  accountAppliedBudget: {
    key: 'accountAppliedBudget',
    label: 'Applied Budget',
    sortable: true,
    thClass: 'text-right',
    class: 'text-right',
    tooltip: 'Total amount allocated to monthly recurring budgets as of right now',
    formatter: formatter('currency-precise'),
    tdClass: 'font-numeric',
    defaultWidth: 110,
    summable: {
      sum: true,
      average: true,
      format: 'currency-precise',
      fieldname: 'budget'
    }
  },
  nonBudgetedMonthlySpend: {
    key: 'nonBudgetedMonthlySpend',
    label: 'Other Spend',
    sortable: true,
    thClass: 'text-right',
    class: 'text-right',
    tooltip: 'Spend that occurred outside of the current budget. In most cases, this spend occurred on a campaign that was paused mid-month.',
    formatter: formatter('currency-precise'),
    tdClass: 'font-numeric',
    defaultWidth: 110,
    summable: {
      sum: true,
      average: true,
      format: 'currency-precise',
      fieldname: 'nonBudgetedMonthlySpend'
    }
  },
  nextApprovedAccountBudget: {
    key: 'nextApprovedAccountBudget',
    label: 'Next Mo. Budget',
    thClass: 'text-right',
    formatter: formatter('currency-precise'),
    tdClass: 'font-numeric',
    sortable: true,
    summable: {
      sum: true,
      average: true,
      format: 'currency-precise'
    }
  },
  conservativeBudgetIncrease: {
    key: 'conservativeBudgetIncrease',
    label: 'Proposal',
    formatter: formatter('currency-precise'),
    tdClass: 'font-numeric',
    sortable: true
  },
  assignedUserName: { key: 'assignedUserName', label: 'Assigned User', class: 'text-left truncate', sortable: true, defaultWidth: 110 },
  autoRemoveBlockingKeywordOnUnderspend: { key: 'autoRemoveBlockingKeywordOnUnderspend', label: 'Manage Blocked', tooltip: 'Auto remove blocking keyword on underspend', defaultWidth: 75 },
  bid: {
    defaultWidth: 100,
    tdClass: 'font-numeric',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  biddingStrategy: {
    key: 'biddingStrategy',
    label: 'Bid Strategy',
    sortable: true,
    defaultWidth: 140,
    tdClass: 'text-truncate',
    formatter: formatter('bid-strategy')
  },
  bidAmount: {
    key: 'bid',
    label: 'Max Bid',
    formatter: formatter('currency-precise'),
    tdClass: 'font-numeric',
    sortable: true,
    thClass: 'text-right',
    summable: {
      fieldname: 'bid',
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  bidModifier: {
    key: 'bidModifier',
    tdClass: 'font-numeric',
    label: 'Bid adj.',
    sortable: true
  },
  targetingSetting: {
    key: 'targetingSetting',
    label: 'Targeting Setting',
    sortable: false
  },
  bidAdjustmentPercentageOnUnderspend: {
    key: 'bidAdjustmentPercentageOnUnderspend',
    label: 'Bid Adj.',
    class: 'text-right',
    tdClass: 'white-space-no-wrap font-numeric',
    formatter: formatter('percentage'),
    tooltip: 'If an account begins to underspend, the first step the system will take is to place a bid adjustment of the entire percent on the campaigns under that budget',
    defaultWidth: 75
  },
  budgetWeight: {
    key: 'budgetWeight',
    label: 'Budget Weight',
    thClass: 'text-right',
    formatter: formatter('percentage'),
    tdClass: 'font-numeric',
    sortable: true,
    tooltip: 'For Facebook, you can specify how much of the campaign budget can be allocated to an ad group',
    summable: {
      sum: true,
      average: false,
      format: 'percentage'
    }
  },
  broadMatchBid: { key: 'broadMatchBid', label: 'B', tdClass: 'white-space-no-wrap' },
  newBroadMatchBid: { key: 'newBroadMatchBid', label: 'B', formatter: formatter('currency-precise'), tdClass: 'font-numeric' },
  isEnhancedBroadMatch: { key: 'isEnhancedBroadMatch', label: 'B+' },
  newIsEnhancedBroadMatch: { key: 'newIsEnhancedBroadMatch', label: 'B+' },
  exactMatchBid: { key: 'exactMatchBid', label: 'E', tdClass: 'white-space-no-wrap' },
  newExactMatchBid: { key: 'newExactMatchBid', label: 'E', formatter: formatter('currency-precise'), tdClass: 'font-numeric' },
  phraseMatchBid: { key: 'phraseMatchBid', label: 'P', tdClass: 'white-space-no-wrap' },
  newPhraseMatchBid: { key: 'newPhraseMatchBid', label: 'P', formatter: formatter('currency-precise'), tdClass: 'font-numeric' },
  qualityScore: { key: 'qualityScore', label: 'QS', sortable: true, tooltip: 'Quality Score' },
  firstPositionCpc: {
    key: 'firstPositionCpc',
    label: 'First CPC',
    formatter: formatter('currency-precise'),
    tdClass: 'font-numeric',
    sortable: true,
    class: 'text-right',
    tooltip: 'First position CPC ($)'
  },
  topOfPageCpc: {
    key: 'topOfPageCpc',
    label: 'Top CPC',
    formatter: formatter('currency-precise'),
    tdClass: 'font-numeric',
    sortable: true,
    class: 'text-right',
    tooltip: 'Top of Page CPC ($)'
  },
  budget: {
    key: 'budget',
    label: 'Applied Budget',
    formatter: formatter('currency-precise'),
    sortable: true,
    thClass: 'text-right',
    tdClass: 'white-space-no-wrap font-numeric',
    tooltip: 'Monthly recurring budget amount. This value is independent of the viewable time range.',
    class: 'text-right',
    defaultWidth: 110,
    summable: {
      sum: true,
      average: true,
      format: 'currency-precise'
    }
  },
  budgetAmount: {
    key: 'budgetAmount',
    label: 'Budget',
    formatter: formatter('currency-precise'),
    tdClass: 'text-right budget-amount font-numeric',
    sortable: true,
    thClass: 'text-right',
    tooltip: 'Monthly recurring budget amount. This value is independent of the viewable time range.',
    defaultWidth: 110,
    summable: {
      sum: true,
      average: true,
      format: 'currency-precise'
    }
  },
  nextBudgetAmount: {
    key: 'nextBudgetAmount',
    label: 'Next Mo. Budget',
    formatter: formatter('currency-precise'),
    tdClass: 'text-right budget-amount font-numeric',
    sortable: true,
    thClass: 'text-right',
    defaultWidth: 110,
    summable: {
      sum: true,
      average: false,
      format: 'currency-precise'
    },
    tooltip: 'Set budget to this dollar amount at the start of next month.'
  },
  paceToMetricValue: {
    key: 'paceToMetricValue',
    label: 'Metric Goal'
  },
  metricPacing: {
    key: 'metricPacing',
    label: 'Metric Pacing'
  },
  paceToMetric: {
    key: 'paceToMetric',
    label: 'Pace to Metric'
  },
  startDate: {
    key: 'startDate',
    label: 'Start Date',
    tdClass: 'white-space-no-wrap font-numeric',
    sortable: true,
    formatter: formatter('date'),
    defaultWidth: 110
  },
  endDate: {
    key: 'endDate',
    label: 'End Date',
    tdClass: 'white-space-no-wrap font-numeric',
    sortable: true,
    formatter: formatter('date'),
    defaultWidth: 110
  },
  optimizedBudgetAmount: {
    key: 'optimizedBudgetAmount',
    label: 'Daily Target',
    formatter: formatter('currency-precise'),
    tdClass: 'text-right budget-amount font-numeric',
    sortable: true,
    thClass: 'text-right',
    tooltip: 'This is the amount that budget pacing has chosen for today\'s spend target',
    defaultWidth: 110,
    option: 'static',
    summable: {
      sum: true,
      average: true,
      format: 'currency-precise'
    }
  },
  budgetBiasStrategy: { key: 'budgetBiasStrategy', label: 'Pacing Strategy', defaultWidth: 120 },
  budgetChannel: { key: 'budgetChannel', label: 'Channel', sortable: true, dataKey: 'advertisingChannelId', defaultWidth: 110 },
  budgetCurrentTotalSpend: {
    key: 'budgetCurrentTotalSpend',
    label: 'Spend',
    formatter: formatter('currency-precise'),
    tdClass: 'font-numeric',
    class: 'text-right',
    sortable: true,
    dataKey: 'cost',
    summable: {
      fieldname: 'cost',
      sum: true,
      average: true,
      format: 'currency-precise'
    }
  },
  // budgetDelete: { key: 'budgetDelete', label: '' },
  budgetManagerPaused: { key: 'budgetManagerPaused', label: '', sortable: false, defaultWidth: 45 },
  budgetName: { key: 'budgetName', label: 'Budget Name', sortable: true, class: 'text-left truncate', dataKey: 'name', defaultWidth: 250, sticky: true },
  campaignGroupName: { key: 'campaignGroupName', label: 'Group', sortable: true, class: 'truncate', dataKey: 'campaignGroupName', defaultWidth: 250 },
  campaignGroupId: { key: 'campaignGroupId', label: 'Group ID', sortable: true, class: 'truncate', filter: true, filterOptions: ['numeric'], defaultWidth: 250, formatter: formatter('fixed') },
  budgetSpendSoFar: { key: 'budgetSpendSoFar', label: 'Spend This Month', class: 'text-left', defaultWidth: 150 },
  budgetType: {
    key: 'budgetType',
    label: 'Budget Type',
    formatter: formatter('capitalize'),
    type: 'capitalize',
    tdClass: 'text-capitalize budget-type'
  },
  budgetShared: {
    key: 'budgetShared',
    label: '',
    tdClass: 'text-center px-0 pb-0',
    thClass: 'text-center px-0',
    defaultWidth: 20,
    sticky: true,
    dataKey: 'explicitlyShared'
  },
  camp: { key: 'camp', label: 'Campaigns\n(Active/Total)', tdClass: 'text-center', thClass: 'text-center', tooltip: 'Active / In-Active Campaign Counts', defaultWidth: 110 },
  campaignName: { key: 'campaignName', label: 'Campaign', class: 'text-left truncate', sortable: false, defaultWidth: 250 },
  campaignPlanName: { key: 'campaignPlanName', label: 'Campaign', class: 'text-left truncate', sortable: true, defaultWidth: 250 },
  campaignChannel: { key: 'campaignChannel', label: 'Channel', sortable: true, dataKey: 'advertisingChannelId', defaultWidth: 110 },
  creativeType: {
    key: 'creativeType',
    class: 'text-left truncate',
    sortable: true,
    formatter: formatter('from-caps-underscore'),
    defaultWidth: 150
  },
  creativePlan: { key: 'creativePlan', label: 'Ad Preview', class: 'text-left ad-preview text-wrap overflow-auto-x', defaultWidth: 250, sticky: true, filterFunction: creativePlanFilter },
  channels: { key: 'channels', label: 'Partner', tdClass: 'pl-4', sortable: true, filterFunction: networkFilter, defaultWidth: 110 },
  clicks: {
    key: 'clicks',
    label: 'Clicks',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  searchClicks: { key: 'searchClicks', label: 'Clicks', sortable: true, formatter: formatter('number'), tdClass: tdClassFormatter },
  clickThroughRate: {
    key: 'clickThroughRate',
    label: 'CTR',
    formatter: formatter('percentage'),
    sortable: true,
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: false,
      format: 'percentage'
    }
  },
  optimizationScore: {
    key: 'optimizationScore',
    label: 'Optimization Score',
    formatter: formatter('percentage'),
    sortable: true,
    tdClass: tdClassFormatter,
    thClass: 'text-right optimizationScore',
    defaultWidth: 90,
    summable: {
      sum: true,
      average: true,
      format: 'percentage'
    }
  },
  optimizedDailyAmount: {
    key: 'optimizedDailyAmount',
    label: 'Today\'s Target',
    formatter: formatter('currency-precise'),
    sortable: true,
    tdClass: tdClassFormatter,
    thClass: 'text-right optimizationScore',
    defaultWidth: 90,
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  Conv: { key: 'Conv', label: 'Conv.', formatter: formatter('percentage'), sortable: true, tdClass: tdClassFormatter },
  contentTemplateName: { key: 'contentTemplateId', label: 'Blueprint', sortable: true, defaultWidth: 90, tdClass: 'white-space-no-wrap' },
  contentTemplateNameFullText: {
    key: 'contentTemplateNameFullText',
    label: 'Strategy',
    sortable: true,
    tdClass: 'text-left',
    thClass: 'text-left'
  },
  conversionRate: {
    key: 'conversionRate',
    label: 'Conv. Rate',
    formatter: formatter('percentage'),
    sortable: true,
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: false,
      format: 'percentage'
    }
  },
  conversions: {
    key: 'conversions',
    label: 'Conv.',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  offlineConversions: {
    key: 'offlineConversions',
    label: 'Offline Conv.',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  callConversions: {
    key: 'callConversions',
    label: 'Calls',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  webConversions: {
    key: 'webConversions',
    label: 'Web Conv.',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  storeConversions: {
    key: 'storeConversions',
    label: 'Store Conv.',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  pageViewConversions: {
    key: 'pageViewConversions',
    label: 'Page Conv.',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  otherConversions: {
    key: 'otherConversions',
    label: 'Other Conv.',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  costPerClick: {
    key: 'costPerClick',
    label: 'CPC',
    formatter: formatter('currency-precise'),
    sortable: true,
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: false,
      format: 'currency'
    }
  },
  costPerConversion: {
    key: 'costPerConversion',
    label: 'Cost Per Conv.',
    formatter: formatter('currency-precise'),
    sortable: true,
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: false,
      format: 'currency'
    }
  },
  totalConversionValue: {
    key: 'totalConversionValue',
    label: 'Conv. Value',
    formatter: formatter('currency-precise'),
    sortable: true,
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: false,
      format: 'currency'
    }
  },
  totalConversionValuePerClick: {
    key: 'totalConversionValuePerClick',
    label: 'Conv. Value / Click',
    formatter: formatter('currency-precise'),
    sortable: true,
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: false,
      average: true,
      format: 'currency'
    }
  },
  totalConversionValuePerCost: {
    key: 'totalConversionValuePerCost',
    label: 'Conv. Value / Cost',
    formatter: formatter('fixed', 2),
    sortable: true,
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: false,
      average: true
    }
  },

  costPerThousandImpressions: {
    key: 'costPerThousandImpressions',
    label: 'CPM',
    formatter: formatter('currency-precise'),
    tdClass: 'font-numeric',
    sortable: true,
    summable: {
      sum: true,
      average: false,
      format: 'currency'
    }
  },
  costPerThousandReach: {
    key: 'costPerThousandReach',
    label: 'CPR',
    formatter: formatter('currency-precise'),
    tdClass: 'font-numeric',
    sortable: true,
    tooltip: 'Cost per thousand people reached',
    summable: {
      sum: true,
      average: false,
      format: 'currency'
    }
  },
  cost: {
    key: 'cost',
    label: 'Cost',
    formatter: formatter('currency-precise'),
    tooltip: 'Spend during the viewable time range',
    sortable: true,
    thClass: 'text-right',
    tdClass: tdClassFormatter,
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  lifetimeCost: {
    key: 'lifetimeCost',
    label: 'Flighted Cost',
    tooltip: 'Spend of flighted campaigns during the viewable time range',
    formatter: formatter('currency-precise'),
    tdClass: 'font-numeric',
    sortable: true,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  totalLifetimeCost: {
    key: 'totalLifetimeCost',
    label: 'All-time Flighted Cost',
    tooltip: 'All-time spend of flighted campaigns that are active as of today',
    formatter: formatter('currency-precise'),
    tdClass: 'font-numeric',
    sortable: true,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  searchCost: {
    key: 'searchCost',
    label: 'Cost',
    formatter: formatter('currency-precise'),
    sortable: true,
    tdClass: tdClassFormatter
  },
  CPC: { key: 'CPC', label: 'CPC', formatter: formatter('currency-precise'), tdClass: 'font-numeric', sortable: true },
  cpc: { key: 'cpc', label: 'CPC', formatter: formatter('currency-precise'), tdClass: 'font-numeric', sortable: true },
  CPL: { key: 'CPL', label: 'CPL', formatter: formatter('currency-precise'), tdClass: 'font-numeric', sortable: true },
  cpl: { key: 'cpl', label: 'CPL', formatter: formatter('currency-precise'), tdClass: 'font-numeric', sortable: true },
  CPM: { key: 'CPM', label: 'CPM', formatter: formatter('currency-precise'), tdClass: 'font-numeric', sortable: true },
  cpm: { key: 'cpm', label: 'CPM', formatter: formatter('currency-precise'), tdClass: 'font-numeric', sortable: true },
  CTR: { key: 'CTR', label: 'CTR', formatter: formatter('percentage'), tdClass: 'font-numeric', sortable: true },
  ctr: { key: 'ctr', label: 'CTR', formatter: formatter('percentage'), tdClass: 'font-numeric', sortable: true },
  dashboardLink: {
    key: 'dashboardLink',
    label: 'Dash',
    thClass: 'text-center',
    tdClass: 'text-center',
    sortable: false,
    defaultWidth: 45
  },
  delete: { key: 'delete', label: '' },
  deliveryMethod: {
    key: 'deliveryMethod',
    label: 'Delivery',
    formatter: formatter('capitalize'),
    tdClass: 'text-capitalize text-left',
    thClass: 'text-left'
  },
  destinationUrl: {
    key: 'destinationUrl',
    label: 'Destination URL',
    class: 'text-left',
    tdClass: 'truncate position-relative',
    defaultWidth: 250
  },
  engagement: {
    key: 'engagement',
    label: 'Engage.',
    sortable: true,
    formatter: formatter('number'),
    tdClass: 'font-numeric',
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  frequency: {
    key: 'frequency',
    label: 'Freq.',
    sortable: true,
    formatter: formatter('fixed', 2),
    tdClass: 'font-numeric',
    thClass: 'text-right',
    summable: {
      sum: false,
      average: true,
      format: 'fixed-2'
    }
  },
  geoTargetExpansionAbsoluteMaxInMiles: {
    key: 'geoTargetExpansionAbsoluteMaxInMiles',
    tdClass: 'white-space-no-wrap',
    label: 'Geo Max',
    tooltip: 'If an account is still underspending after step three, the final step the system will take is a target radius of the entered miles will be placed on the campaigns within the budget',
    defaultWidth: 75
  },
  headline1: { key: 'headline1', label: 'Creative', sortable: true, class: 'text-left' },
  impressions: {
    key: 'impressions',
    label: 'Impr.',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  searchImpressions: {
    key: 'searchImpressions',
    label: 'Impr.',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter
  },
  impressionShare: {
    key: 'impressionShare',
    label: 'Impr. Share',
    sortable: true,
    formatter: formatter('percentage'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: false,
      average: false,
      format: 'percentage'
    }
  },
  impressionShareLostToRank: {
    key: 'impressionShareLostToRank',
    label: 'Lost to Rank',
    sortable: true,
    formatter: formatter('percentage'),
    tdClass: tdClassFormatter
  },
  impressionShareLostToBudget: {
    key: 'impressionShareLostToBudget',
    label: 'Lost to Budget',
    sortable: true,
    formatter: formatter('percentage'),
    tdClass: tdClassFormatter
  },
  avgLostToRank: {
    key: 'avgLostToRank',
    label: 'Lost to Rank',
    sortable: true,
    formatter: formatter('percentage'),
    tdClass: tdClassFormatter,
    summable: {
      sum: false,
      average: false,
      format: 'percentage'
    }
  },
  avgLostToBudget: {
    key: 'avgLostToBudget',
    label: 'Lost to Budget',
    sortable: true,
    formatter: formatter('percentage'),
    tdClass: tdClassFormatter,
    summable: {
      sum: false,
      average: false,
      format: 'percentage'
    }
  },
  keywordText: { key: 'keywordText', label: 'Keyword', sortable: true, class: 'text-left truncate', defaultWidth: 250, sticky: true },
  labels: { key: 'labels', label: 'Labels', sortable: false, class: 'text-left truncate', defaultWidth: 250 },
  campaignLabels: { key: 'campaignLabels', dataKey: 'labels', label: 'Labels', sortable: false, thClass: 'text-left', tdClass: 'text-left position-relative', defaultWidth: 250, formatter: (value, key, item) => item.labels?.join(', ') ?? '' },
  budgetCampaignLabels: { key: 'budgetCampaignLabels', dataKey: 'campaignLabels', label: 'Campaign Labels', sortable: false, thClass: 'text-left', tdClass: 'text-left position-relative', defaultWidth: 250, formatter: (value, key, item) => item.campaignLabels?.join(', ') ?? '' },
  adGroupLabels: { key: 'adGroupLabels', dataKey: 'labels', label: 'Labels', sortable: false, thClass: 'text-left', tdClass: 'text-left position-relative', defaultWidth: 250, formatter: (value, key, item) => item.labels?.join(', ') ?? '' },
  leads: {
    key: 'leads',
    label: 'Leads',
    sortable: true,
    formatter: formatter('number'),
    tdClass: 'font-numeric',
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  lifetimeBudget: {
    key: 'lifetimeBudget',
    label: 'Flighted Budget',
    tooltip: 'Amount allocated to flighted campaigns as of today',
    sortable: true,
    formatter: formatter('currency-precise'),
    tdClass: 'font-numeric',
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency-precise'
    }
  },
  webLeads: {
    key: 'webLeads',
    label: 'Website Leads',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  platformLeads: {
    key: 'platformLeads',
    label: 'Platform Leads',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  costPerLead: {
    key: 'costPerLead',
    label: 'Cost per Lead',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  costPerWebLead: {
    key: 'costPerWebLead',
    label: 'Cost per Website Lead',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  costPerPlatformLead: {
    key: 'costPerPlatformLead',
    label: 'Cost per Platform Lead',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  leadReachRate: {
    key: 'leadReachRate',
    label: 'Lead/Reach Rate',
    sortable: true,
    formatter: formatter('percentage', 2),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: false,
      average: true,
      format: 'percentage'
    }
  },
  name: { key: 'name', label: 'Name', sortable: true, class: 'text-left truncate', defaultWidth: 250, sticky: true },
  ownerUuid: { key: 'ownerUuid', label: 'UUID', tdClass: 'text-left truncate text-small', thClass: 'text-left', defaultWidth: 150 },
  openAdSchedulesOnUnderspend: {
    key: 'openAdSchedulesOnUnderspend',
    label: 'Relax Ad Schedules',
    tooltip: 'If an account is still underspending after step two, the third step the system will take it to relax and remove ad schedules',
    defaultWidth: 75
  },
  healthScore: {
    key: 'healthScore',
    label: 'Optimization Score',
    formatter: formatter('percentage'),
    sortable: true,
    tooltip: 'Currently composed directly from Google Optimization Score',
    tdClass: tdClassFormatter,
    defaultWidth: 90,
    summable: {
      average: true,
      sum: false,
      format: 'percentage'
    }
  },
  pacing: {
    key: 'pacing',
    label: 'Pacing',
    formatter: formatter('percentage'),
    sortable: true,
    tdClass: tdClassFormatter,
    defaultWidth: 130,
    summable: {
      average: true,
      sum: false,
      format: 'percentage'
    }
  },
  sharedApprovedAccountBudget: {
    key: 'sharedApprovedAccountBudget',
    label: 'Group Budget',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  approvedBudgetPacing: {
    key: 'approvedBudgetPacing',
    label: 'Pacing (to Approved)',
    formatter: formatter('percentage'),
    sortable: true,
    tdClass: tdClassFormatter,
    defaultWidth: 130,
    summable: {
      average: true,
      sum: false,
      format: 'percentage'
    }
  },
  postalCode: { key: 'postalCode', label: 'Postal Code', tdClass: 'text-left', thClass: 'text-left' },
  proratePartialMonth: { key: 'proratePartialMonth', label: 'Prorate', tdClass: 'pr-0' },
  reach: {
    key: 'reach',
    label: 'Reach',
    formatter: formatter('number'),
    tdClass: 'font-numeric',
    sortable: true,
    thClass: 'text-right',
    summable: {
      average: true,
      sum: false,
      format: 'number'
    }
  },
  readOnlyMode: { key: 'readOnlyMode', label: 'Managed', class: 'text-center', sticky: true },
  stat: { key: 'stat', label: 'Status', class: 'text-center', sortable: true, dataKey: 'status' },
  status: { key: 'status', thClass: 'text-left', tdClass: 'text-left', defaultWidth: 90, sortable: true },
  state: { key: 'state', label: 'State', tdClass: 'text-left', thClass: 'text-left' },
  selectRow: { key: 'selectRow', sortable: false, label: '', class: 'mr-2 text-left', sticky: true, defaultWidth: 38 },
  servingStatus: { key: 'servingStatus', sortable: true, label: '', class: 'ml-2 text-right', defaultWidth: 65 },
  spendYesterday: {
    key: 'spendYesterday',
    sortable: true,
    formatter: formatter('currency-precise'),
    tdClass: tdClassFormatter,
    label: 'Yesterday\'s Spend',
    defaultWidth: 90,
    summable: {
      sum: true,
      average: false,
      format: 'currency-precise'
    }
  },
  uniqueImpressions: {
    key: 'uniqueImpressions',
    label: 'First Impr.',
    sortable: true,
    formatter: formatter('number'),
    tdClass: 'font-numeric',
    thClass: 'text-right',
    summable: {
      sum: false,
      average: true,
      format: 'number'
    }
  },
  uniqueClicks: {
    key: 'uniqueClicks',
    label: 'Unq Clicks',
    sortable: true,
    formatter: formatter('number'),
    tdClass: 'font-numeric',
    thClass: 'text-right',
    summable: {
      sum: false,
      average: true,
      format: 'number'
    }
  },
  uniqueClickThroughRate: {
    key: 'uniqueClickThroughRate',
    label: 'Unq CTR',
    sortable: true,
    formatter: formatter('percentage'),
    tdClass: 'font-numeric',
    thClass: 'text-right',
    summable: {
      sum: false,
      average: false,
      format: 'percentage'
    }
  },
  uniqueOutboundClicks: {
    key: 'uniqueOutboundClicks',
    label: 'Unq Outbound Clicks',
    sortable: true,
    formatter: formatter('number'),
    tdClass: 'font-numeric',
    thClass: 'text-right',
    summable: {
      sum: false,
      average: true,
      format: 'number'
    }
  },
  uniqueLandingPageViews: {
    key: 'uniqueLandingPageViews',
    label: 'Unq LP Views',
    sortable: true,
    formatter: formatter('number'),
    tdClass: 'font-numeric',
    thClass: 'text-right',
    summable: {
      sum: false,
      average: true,
      format: 'number'
    }
  },
  uniqueLandingPageViewRate: {
    key: 'uniqueLandingPageViewRate',
    label: 'Unq LPV Rate',
    sortable: true,
    formatter: formatter('percentage'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: false,
      average: false,
      format: 'percentage'
    }
  },
  costPerUniqueLandingPageView: {
    key: 'costPerUniqueLandingPageView',
    label: 'Cost per Unq LPV',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: false,
      format: 'currency'
    }
  },
  useBroadMatchOnUnderspend: {
    key: 'useBroadMatchOnUnderspend',
    label: 'Broad Match',
    tooltip: 'If an account is still underspending after step one, the second step the system will take is to add broad match keywords to the account that will match all phrase and exact keywords',
    defaultWidth: 75
  },
  videoViews: {
    key: 'videoViews',
    label: '3 Second Video Views',
    sortable: true,
    formatter: formatter('number'),
    tdClass: 'font-numeric',
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  costPerVideoView: {
    key: 'costPerVideoView',
    label: 'Cost per Video View',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  views: {
    key: 'views',
    label: 'Landing Page Views',
    sortable: true,
    formatter: formatter('number'),
    tdClass: 'font-numeric',
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  costPerLandingPageView: {
    key: 'costPerLandingPageView',
    label: 'Cost per Landing Page View',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  userName: { key: 'userName', label: 'User', sortable: true, formatter: formatter('number'), class: 'text-left', defaultWidth: 250 },
  spend: {
    key: 'totalCost',
    label: 'Spend',
    formatter: formatter('currency'),
    sortable: true,
    tdClass: tdClassFormatter
  },
  criterionType: { key: 'criterionType', label: 'Criterion Type', sortable: true, class: 'text-left truncate', defaultWidth: 250, sticky: true },
  criterionSpec: { key: 'criterionSpec', label: 'Value', sortable: true, class: 'text-left truncate', defaultWidth: 250, sticky: true },
  criterionExcluded: { key: 'criterionExcluded', label: 'Excluded', sortable: true, defaultWidth: 100 },
  criterionBid: { key: 'criterionBid', label: 'Bid Modifier', defaultWidth: 100, sortable: true, sortByFormatted: (v, k, item) => item.bid },
  navigations: { key: 'navigations', label: 'Navigations', sortable: true, defaultWidth: 90 },
  repins: { key: 'repins', label: 'Pin Saves', sortable: true, defaultWidth: 90 },
  pinClicks: { key: 'pinClicks', label: 'Pin Clicks', sortable: true, defaultWidth: 90 },
  outboundClicks: {
    key: 'outboundClicks',
    label: 'Outbound Clicks',
    sortable: true,
    defaultWidth: 90,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  costPerOutboundClick: {
    key: 'costPerOutboundClick',
    label: 'Cost per Outbound Click',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  inlineClicks: {
    key: 'inlineClicks',
    label: 'Link Clicks',
    sortable: true,
    defaultWidth: 90,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  costPerInlineClick: {
    key: 'costPerInlineClick',
    label: 'Cost per Link Click',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  inlineClickRate: {
    key: 'inlineClickRate',
    label: 'Link Click Rate',
    sortable: true,
    formatter: formatter('percentage'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: false,
      average: true,
      format: 'percentage'
    }
  },
  webSessions: { key: 'webSessions', label: 'Web Sessions', sortable: true, defaultWidth: 90 },
  repinRate: {
    key: 'repinRate',
    label: 'Pin Save Rate',
    sortable: true,
    formatter: formatter('percentage'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: false,
      average: false,
      format: 'percentage'
    }
  },
  outboundClickRate: {
    key: 'outboundClickRate',
    label: 'Outbound Click Rate',
    sortable: true,
    formatter: formatter('percentage'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: false,
      average: true,
      format: 'percentage'
    }
  },
  engagementRate: {
    key: 'engagementRate',
    label: 'Engagement Rate',
    sortable: true,
    formatter: formatter('percentage'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: false,
      average: false,
      format: 'percentage'
    }
  },
  purchases: {
    key: 'purchases',
    label: 'Purchases',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  costPerPurchase: {
    key: 'costPerPurchase',
    label: 'Cost per Purchase',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  purchasesWebsite: {
    key: 'purchasesWebsite',
    label: 'Website Purchases',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  costPerPurchaseWebsite: {
    key: 'costPerPurchaseWebsite',
    label: 'Cost per Website Purchase',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  addToCarts: {
    key: 'addToCarts',
    label: 'Add to Cart',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  costPerAddToCart: {
    key: 'costPerAddToCart',
    label: 'Cost per Add To Cart',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  addToCartsWebsite: {
    key: 'addToCartsWebsite',
    label: 'Website Add to Cart',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  costPerAddToCartWebsite: {
    key: 'costPerAddToCartWebsite',
    label: 'Cost per Website Add to Cart',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  initiateCheckouts: {
    key: 'initiateCheckouts',
    label: 'Initiate Checkout',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  costPerInitiateCheckout: {
    key: 'costPerInitiateCheckout',
    label: 'Cost per Initiate Checkout',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  initiateCheckoutsWebsite: {
    key: 'initiateCheckoutsWebsite',
    label: 'Website Initiate Checkout',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  costPerInitiateCheckoutWebsite: {
    key: 'costPerInitiateCheckoutWebsite',
    label: 'Cost per Website Initiate Checkout',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  contentViews: {
    key: 'contentViews',
    label: 'Content View',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  costPerContentView: {
    key: 'costPerContentView',
    label: 'Cost per Content View',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  contentViewsWebsite: {
    key: 'contentViewsWebsite',
    label: 'Website Content View',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  costPerContentViewWebsite: {
    key: 'costPerContentViewWebsite',
    label: 'Cost per Website Content View',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  pageEngagement: {
    key: 'pageEngagement',
    label: 'Page Engagement',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  pageLikes: {
    key: 'pageLikes',
    label: 'Page Likes',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  postEngagement: {
    key: 'postEngagement',
    label: 'Post Engagement',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  result: {
    key: 'result',
    label: 'Result',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  thruplays: {
    key: 'thruplays',
    label: 'Thruplays',
    sortable: true,
    formatter: formatter('number'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  },
  costPerThruplay: {
    key: 'costPerThruplay',
    label: 'Cost per Thruplay',
    sortable: true,
    formatter: formatter('currency'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency'
    }
  },
  daysRemoved: {
    label: 'Days Removed',
    key: 'daysRemoved',
    sortable: true,
    tdClass: 'font-numeric',
    thClass: 'text-right'
  },
  appPurchaseRoas: {
    key: 'appPurchaseRoas',
    label: 'App Purchase ROAS',
    sortable: true,
    formatter: formatter('currency-precise'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency-precise'
    }
  },
  pixelPurchaseRoas: {
    key: 'pixelPurchaseRoas',
    label: 'Pixel Purchase ROAS',
    sortable: true,
    formatter: formatter('currency-precise'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency-precise'
    }
  },
  omniPurchaseRoas: {
    key: 'omniPurchaseRoas',
    label: 'Omni Purchase ROAS',
    sortable: true,
    formatter: formatter('currency-precise'),
    tdClass: tdClassFormatter,
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'currency-precise'
    }
  },
  videoPlays: {
    key: 'videoPlays',
    label: 'Meta Video Plays',
    sortable: true,
    formatter: formatter('number'),
    tdClass: 'font-numeric',
    thClass: 'text-right',
    summable: {
      sum: true,
      average: true,
      format: 'number'
    }
  }
}

export default sharedColumnDefinitions
